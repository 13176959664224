import React from "react";
import { InputWrapper, Wrapper } from "./styled"
import { ErrorMessage, Field, Form, Formik } from "formik";
import { object, string } from "yup";

const Questionairs = () => {

  const onSubmit = (values) => {
    console.log(values);
  }
  return (
    <Wrapper>
      <h1>Got an idea to discuss?</h1>
      <p>Fill in what you like to get more from us. </p>

      <Formik
        initialValues={{
          email: ''
        }}
        validateOnBlur
        validationSchema={object({
          email: string().email('Should be a valid email').required('Email is required'),
        })}
        onSubmit={onSubmit}
      >
        <Form>
          <InputWrapper>
            <div>
              <label htmlFor="email">
                Your E-mail
              </label>
            </div>
            <Field id="email" className="text" name="email" />
            <ErrorMessage component="div" name="email" className="error" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label htmlFor="phone">
                Your Phone Number
              </label>
            </div>
            <Field id="phone" name="phone" className="text" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label htmlFor="idea">
                What is the main idea of the project?
              </label>
            </div>
            <Field id="idea" name="idea" className="text" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label htmlFor="primaryUsers">
                Who are the primary users of the software?
              </label>
            </div>
            <Field id="primaryUsers" name="primaryUsers" className="text" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label htmlFor="timeline">
                What is the desired timeline for completing the project?
              </label>
            </div>
            <Field id="timeline" name="timeline" className="text" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label>
                What are your expectations regarding software testing?
              </label>
            </div>
            <input className="text" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label>
                Are there any legal or compliance requirements?
              </label>
            </div>
            <input className="text" />
          </InputWrapper>
          <InputWrapper>
            <div>
              <label>
                Budget Range (USD)
              </label>
            </div>
            <div className="row">
              <fieldset id="group2">
                <div>
                  <input type="radio" id="below10k" name="budget" value="below10k" checked />
                  <label for="below10k">Below 10K</label>
                </div>
                <div>
                  <input type="radio" id="below50k" name="budget" value="below50k" checked />
                  <label for="below50k">Below 50K</label>
                </div>
                <div>
                  <input type="radio" id="more50k" name="budget" value="more50k" checked />
                  <label for="more50k">More than 50K</label>
                </div>
                <div>
                  <input type="radio" id="undecide" name="budget" value="undecide" checked />
                  <label for="undecide">Not decided</label>
                </div>
              </fieldset>
            </div>
          </InputWrapper>
          <InputWrapper>
            <div>
              <label>
                Additional information about project such as features and technology
              </label>
            </div>
            <textarea>
            </textarea>
          </InputWrapper>

          <button type="button">
            Let's get started
          </button>
        </Form>
      </Formik>

    </Wrapper>
  )
}

export default Questionairs;